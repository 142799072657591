<template>
  <div v-if="!isSearch" class="allPage">
    <heads :show_search="showSearch" :msg="title_msg" @doSearch="doSearch"></heads>
    <van-list
      v-show="showList1"
      v-model="loading1"
      :finished="finished1"
      finished-text="没有更多了"
      @load="onLoadTZ"
    >
      <div v-for="(thread,index) in tz_list" :key="index" @click="go_url(thread.url_bbs)">
        <datalists
          :type="1"
          :list-type="2"
          :thumb="thread.pic"
          :atitle="thread.title"
          :create-time="thread.create_time"
          :v-num="thread.view_num"
          :c-num="thread.reply_num"
        ></datalists>
      </div>
    </van-list>
    <div v-show="!showList1" class="kongzt">
      <img src="../../static/images/my_index/kongzt.png" alt />
      <p>{{tip}}</p>
    </div>
  </div>
  <div v-else>
    <div class="div_search">
      <van-search
              style="width: 100%"
              v-model="search_value"
              shape="round"
              @keyup.enter.native="searchPost"
              placeholder="搜索我的发帖"
      />
      <span @click="searchPost">搜索</span>
    </div>
    <div class="div_search_content">
      <div class="div_title">
        <span>历史搜索</span>
      </div>
      <div class="div_list">
        <span class="div_list_item" v-for="item in searchList"  :slot="item" @click="onLoadTZ(item.word,1)">{{item.word}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import tool from '@/utils/tools.js'
import { get_tz_see } from '@/api/my_user.js'
import datalists from '@/compontens/datalist'
import {addSearchHistory, getSearchHistory} from "@/api/my_user";

export default {
  components: {
    heads,
    datalists
  },
  data() {
    return {
      isSearch: false,
      showSearch: true,
      tip:'您还没有浏览帖子哦~',
      search_value:'',
      searchList:[],
      title_msg: '浏览帖子',
      active: 0,
      limit: 10,
      tz_page: 1,
      tz_count: 0,
      tz_list: [],
      isshow: false,
      finished: false,
      showList1: true,
      loading1: false,
      finished1: false,
      showMore: true
    }
  },
  destroyed() {
    sessionStorage.removeItem('h_index')
  },
  mounted() {
    const index = sessionStorage.getItem('h_index') ? sessionStorage.getItem('h_index') : 0
    this.active = Number(index)
    this.get_tz_list()
    this.getSearchHistory()
  },
  methods: {
    getSearchHistory(){
      getSearchHistory({type:2}).then(res =>{
        if (res.errNo === 0){
          this.searchList = res.result
        }
      })
    },
    searchPost(){
      if (this.search_value){
        addSearchHistory({word:this.search_value,type:2}).then(res =>{
        })
        this.isSearch = false
      }else {
        this.isSearch = false
      }
      this.onLoadTZ(this.search_value,1)
    },
    doSearch(){
      this.getSearchHistory()
      this.search_value = ''
      this.showList1 = true
      this.finished1 = false
      this.isSearch = true
    },
    // 跳帖子详情
    go_url(urls) {
      window.location.href = urls
    },
    formatDate(date) {
      var date = new Date(date * 1000)
      return tool.formatDate(date, 'yyyy-MM-dd')
    },
    clickTabs(val) {
      sessionStorage.setItem('h_index', val)
    },
    clicks() {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    onLoadTZ (val,type) {
        this.isSearch = false
        this.loading = true
        console.log(this.uid);
        if (type == 1){
          this.tz_list = []
          this.tz_page = 1
          this.tip = '您还没有浏览关于【'+val+'】的帖子哦~'
        }else {
          this.tip = '您还没有浏览过帖子哦~'
        }
      if (this.tz_list.length >= this.tz_count && this.tz_list.length>0) {
        // 如果没有数据需要加载了就显示没有更多了
        this.finished1 = true
      } else {
        this.tz_page++
        var params = {
          page: this.tz_page,
          limit: this.limit,
          q:val,
        }
        this.get_tz_list(params)
      }
    },
    get_tz_list(params) {
      this.loading1 = true
      if (!params){
        params = {
          page: this.tz_page,
          limit: this.limit
        }
      }
      get_tz_see(params).then(res => {
        this.loading1 = false
        const list = res.result.list ? res.result.list : []
        this.tz_count = res.result.count ? res.result.count : 0
        this.tz_list = [...this.tz_list, ...list]
        if (!list.length && !this.tz_list.length) {
          this.showList1 = false
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
// .eye_img {
//   width: 28px;
//   height: 18px;
//   position: absolute;
//   bottom: 45px;
//   left: 30px;
// }
// .kuang_img {
//   width: 24px;
//   height: 18px;
//   position: absolute;
//   bottom: 46px;
//   left: 200px;
// }

h4 {
  font-size: 30px;
  word-wrap: break-word;
  word-break: normal;
  width: 353px;
  // letter-spacing: 1px;
  padding: 37px 0 0 32px;
  line-height: 56px;
}
// .people_bro {
//   padding-left: 70px;
//   float: left;
//   color: #9b9b9b;
// }
// .people_gen {
//   float: left;
//   padding-left: 45px;
//   color: #9b9b9b;
//   position: absolute;
//   left: 190px;
//   bottom: 39px;
// }
// .bro_time {
//   float: right;
//   padding: 4px 33px 0 0;
//   color: #9b9b9b;
// }
.main {
  width: 100%;
  height: 276px;
  border-bottom: #efefef 1px solid;
  overflow: hidden;
  position: relative;
}
.ll_img {
  width: 279px;
  height: 157px;
  margin-top: 32px;
}
.main_top_left {
  float: left;
  width: 440px;
  height: 205px;
}
.main_top_right {
  float: left;
  overflow: hidden;
  height: 205px;
}
.thread {
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: flex-end;
  border-bottom: #eee 1px solid;
}
.content {
  // width: calc(100% - 280px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //自动计算剩余宽度
  flex: 1;
  .thread_title {
    margin: 30px 20px 30px 30px;
    font-size: 30px;
    color: #333333;
    line-height: 50px;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .bot_info {
    height: 50px;
    margin-bottom: 20px;
    line-height: 50px;
    .eye_img {
      margin-left: 30px;
      width: 28px;
      height: 18px;
    }
    .people_bro {
      margin-left: 8px;
      color: #999999;
      display: inline-block;
    }
    .kuang_img {
      margin-left: 30px;
      width: 24px;
      height: 18px;
    }
    .people_gen {
      margin-left: 8px;
      color: #999999;
      display: inline-block;
    }
  }
}
.right_box {
  width: 280px;
  height: 250px;
  margin-top: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .thumb {
    width: 100%;
    height: 158px;
  }
  .bot_time {
    height: 50px;
    margin-bottom: 20px;
    text-align: right;
    line-height: 50px;
    color: #999999;
  }
}
/deep/.van-tabs__nav--line {
  padding-bottom: 0px;
}
/deep/.van-tabs__wrap {
  border-bottom: 1px solid #eee;
}
/deep/.van-tabs__line {
  height: 4px;
  bottom: 0px;
  border-radius: 0;
}
.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}
.div_search{
  display: flex;
  align-items: center;
  span{
    flex-shrink: 0;
    width: 80px;
    text-align: left;
    font-size: 30px;
  }
}
.div_search_content{
  margin: 24px 24px;
  .div_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    span{
      font-size: 32px;
      color: #333333;
    }
  }
  .div_list{
    display: flex;
    flex-wrap: wrap;
    height: 290px ;
    overflow-y: hidden;
    .div_list_item{
      flex-shrink: 0;
      max-width: 180px;
      height: 50px;
      text-align: center;
      line-height: 54px;
      align-items: center;
      padding: 12px 26px;
      border-radius: 16px;
      margin-right: 24px;
      margin-bottom: 24px;
      background: #F6F6F6;
      white-space: nowrap;
      overflow: hidden;//文本超出隐藏
      text-overflow: ellipsis;//文本超出省略号替代
    }
  }
}
.allPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
</style>
